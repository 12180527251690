<template>
  <div id="main">
    <div class="header__container">
      <div class="pre-page__button"><el-button @click="goBack"><i class="el-icon-arrow-left"></i></el-button></div>
      <p class="heaader-title">내 정보수정</p>
    </div>
    <div class="mypage-info-contact__wrap">
      <div class="text__line">
        <p class="title">
          010-6413-****의<br>
          뒷자리를 입력해주세요.
        </p>
        <p class="text">입력 후 회원정보 조회 및 수정이 가능해요</p>
      </div>
      <div class="input__line">
        <el-input v-model.number="tel" autocomplete="off" maxlength="4"></el-input>
        <div class="help">
          <router-link to="/">휴대폰 번호를 잊으셨나요?</router-link>
        </div>
      </div>
    </div>
    <div class="footer__container">
      <el-button class="footer__line">확인</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'infoContact',
  components: {},
  data() {
    return {
      tel: '',
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
